import { isMobileOnly } from "react-device-detect"
import { navigate } from "@libs"
import {
  getRegistrationPageFlowSettings,
  URL_SIGNUP_REGISTER,
} from "@constants"

const Signup = () => {
  const pageFlow = getRegistrationPageFlowSettings(isMobileOnly)

  if (!pageFlow || pageFlow.length === 0) {
    navigate(URL_SIGNUP_REGISTER)
    return null
  }

  navigate(pageFlow[0].pathname)
  return null
}

export default Signup
